.swiper-slide-thumb-active {
    box-shadow: inset 0 0 0 4px #000;
}

.swiper-slide-thumb-active .slide-overlay {
    opacity: 0;
}

:root {
  --swiper-theme-color: #ffffff;
  --swiper-pagination-bullet-inactive-color: #3B424995;
  --swiper-pagination-bullet-inactive-opacity: 0.9;
  --swiper-pagination-color: white;
  --swiper-pagination-bullet-size: 12px;
  --swiper-pagination-bullet-border-radius: 50%;
  --swiper-pagination-bullet-horizontal-gap: 5px;

}

/* Customize Swiper navigation styles */
.swiper-button-prev,
.swiper-button-next {
  background-color: #3B424995;
  color: #fff;
  /*border: 1px white solid; */
  border: none;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  font-size: 24px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  --swiper-navigation-size: 24px;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: white;
}

/* Initially hide Swiper navigation buttons */
.simple-image-swiper-navigation-behavior.swiper .swiper-button-prev,
.simple-image-swiper-navigation-behavior.swiper .swiper-button-next {
  opacity: 1;
  width: 1.5rem;
  height: 1.5rem;
  --swiper-navigation-size: 8px;
}

.simple-image-swiper-navigation-behavior.swiper:hover .swiper-button-prev,
.simple-image-swiper-navigation-behavior.swiper:hover .swiper-button-next {
  background-color: #fff;
  color: #000;
  opacity: 1;
}
